<template>
  <div class="ma-10">
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectFilterAffiliation"
              :items="filterAffiliations"
              label="所属"
              clearable
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actions -->
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5">
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn
          icon="mdi-lead-pencil"
          color="success"
          :disabled="$isEmpty(items.length)"
          @click="upsert"
        >
          更新
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            draggable
            @dragstart="dragList($event, index)"
            @drop="dropList($event, index)"
            @dragover.prevent
            @dragenter.prevent
          >
            <td>{{ item.userCode }}</td>
            <td>{{ item.userName }}</td>
            <td>{{ item.orderNo }}</td>
            <td>
              <v-icon
                v-if="item.canDelete"
                small
                @click="deleteAction(item)"
                class="ml-1"
                color="error"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
          <tr v-if="display">
            <Btn color="info" icon="plus" text @click="adduser">社員追加</Btn>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import ShiftUserAddDialogVue from "./dialogs/ShiftUserAddDialog.vue";
import ShiftOrder from "@/models/ShiftOrder";

export default {
  name: "ShiftOrder",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      panel: null,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      filterAffiliations: [],
      selectFilterAffiliation: "",
      users: [],
      display: false,
      searchedAffiliation: {}
    };
  },
  methods: {
    dragList(event, dragIndex) {
      console.log(dragIndex);
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("drag-index", dragIndex);
    },
    dropList(event, dropIndex) {
      const dragIndex = event.dataTransfer.getData("drag-index");
      const deleteList = this.items.splice(dragIndex, 1);
      this.items.splice(dropIndex, 0, deleteList[0]);
      this.items.map((item, index) => {
        item.orderNo = index + 1;
      });
    },
    async deleteAction(item) {
      console.log("deleteAction", item);
      const confirm = await this.$deleteConfirm(
        "削除します。\nよろしいですか？"
      );
      console.log(confirm);
      if (!confirm) return;

      this.items = this.items.filter(e => e !== item);
      this.items.map((item, index) => {
        item.orderNo = index + 1;
      });
    },
    async adduser() {
      console.log("adduser");

      const param = {
        resulttype: "1",
        affiliationid: this.searchedAffiliation.code,
        affiliationname: this.searchedAffiliation.name,
        users: this.users
      };

      const selectusers = await this.$showDialog(ShiftUserAddDialogVue, {
        args: param
      });

      if (!selectusers) return;

      selectusers.forEach(s => {
        const itemrow = {
          userCode: s.refcode,
          userName: s.name,
          employeeId: s.code,
          affiliationId: this.searchedAffiliation.code,
          orderNo: 0,
          canDelete: true
        };
        this.items.push(itemrow);
      });

      this.items.map((item, index) => {
        item.orderNo = index + 1;
      });
    },
    async upsert() {
      console.log("upsert");

      const records = [];
      this.items.forEach(item => {
        const param = new ShiftOrder(
          this.searchedAffiliation.code,
          item.employeeId,
          item.orderNo
        );
        records.push(param);
      });
      this.$loading();

      try {
        await this.$post(this.Paths.shiftOrder, records);
        await this.$info("更新しました。");
        await this.selectRecord(this.searchedAffiliation.code);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async actions(prosess, args) {
      console.log(prosess, args);
    },
    async clickDelete() {
      if (await this.deleteConfirm())
        this.items = this.items.filter(e => !this.selected.includes(e));
    },
    async getdata(path) {
      this.$loading();
      try {
        return await this.$get(path);
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async selectRecord(affiliationid) {
      console.log("selectRecord", affiliationid);

      const param = { shozoku: affiliationid };
      const encode = encodeURI(JSON.stringify(param));

      const data = await this.$get(this.Paths.shiftOrder, "query=" + encode);
      await this.setdata(data);

      if (this.selectFilterAffiliation) {
        this.display = true;
        const filter = this.filterAffiliations.filter(
          e => e.code === this.selectFilterAffiliation
        );
        this.searchedAffiliation = filter[0];
      } else {
        this.display = false;
        this.searchedAffiliation = {};
      }
      console.log(this.searchedAffiliation);
    },
    async select() {
      console.log("select");
      this.$loading();
      try {
        await this.selectRecord(this.selectFilterAffiliation);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async update() {
      console.log("==update");
      this.$loading();
      try {
        this.filterAffiliations = await this.$get(this.Paths.shozokuFilter);
        this.users = await this.$get(this.Paths.shainSorted);
        const data = await this.$get(this.Paths.shiftOrder);
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.selected = [];
      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
    }
  },
  created() {
    console.log("==created");
    this.display = false;
    this.update();
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}
</style>
